<template>
  <div>
    <div class="select-simulation-container" v-show="active">
      <div class="close-simulation-panel-btn" @click="toggleParameterPanel()">
        <b-icon class="chevron-icon" icon="chevron-down"></b-icon>
      </div>
      <div  v-show="scenarioSubmissionInProgress">
        Saving simulation parameters...
        <b-spinner class="ml-2" small></b-spinner>
      </div>

      <FormulateForm  v-show="!scenarioSubmissionInProgress"
        v-model="simulationParameters"
        @validation="formValidationStateChanged"
        #default="{ isLoading }">
        <FormulateInput name="formVersion" :value="cfdParameterFormVersion" hidden />
        <!-- embed a version number for the form.  This will get stored with the input json and as the form eloves, this will ake it possible to know if the stored json is compatible with the current version of the form -->
        <div class="row">
          <h5 class="col-5">Simulation Parameters</h5>
          <b-form-checkbox
            class='mt-0 toggle-form-validation-checkbox col-5 d-flex flex-row-reverse'
            @change='toggleFormValidation'
            :checked='formValidationDisabled'
            v-if="companyIsRWDI || userIsSuperuser"
            >
            Disable form validation
          </b-form-checkbox>
        </div>
        <div class="row" v-if="simulationNeedsMet">
          <div v-if="metSourceOptions.length == 0" class="d-flex flex-row align-items-center no-top-margin-all-descendants">
            <div class="ml-3 alert alert-danger">
              {{weatherErrorMsg}}
            </div>
            <router-link class="ml-2 mb-2" :to="routeTo()"><b-icon-plus-circle /> Add Weather Data</router-link>
          </div>
          <div v-else class="col-6 d-flex flex-row align-items-center">
            <span class="mr-2 w-50">Weather Data: </span> 
            <b-form-select v-model='selectedMetSourceId' small :options="metSourceOptions" />
            <router-link class="w-50 ml-2 mt-0" :to="routeTo()"><b-icon-plus-circle /></router-link>
          </div>
        </div>
        <b-tabs content-class="mt-3 pt-0">
          <b-tab v-for='criterion in selectedCriteriaWithParams' :key='criterionKey(criterion)'>
            <template #title v-if="!isLoading">
              <!-- Cant use a computed property because selectedCriteriaWithParams may still be loading -->
              <img class="pb-1" src="~@/assets/svg/exclamation-circle-fill.svg" alt="Directional Scaled Wind Error"  v-if="showCriteriaError(criterion)"  id ="criteria-error" >
              {{criterionLabel(criterion)}}
            </template>
             <FormulateInput  type='group' :name='criterionKey(criterion)' >
              <div v-if='criterionRequiresSeasons(criterion)' class='no-top-margin-all-descendants'>
                <h4 class="input-title mb-2">Seasons</h4>
                <label class='input-label'>Copy initial parameters from</label>
                <FormulateInput
                  name='useSameSeasonsAs'
                  type='select'
                  class='use-same-as-dropdown'
                  ref="seasons"
                  @change="copyFieldFromOtherMetricToCriterion('Seasons', $event, criterion)"
                  :disabled="isDemoProject"
                  :options="useSameAsFieldForCriterionOptions('Seasons', criterion)"/>
                <FormulateInput class="no-top-margin-all-descendants" type="group" name="Seasons" :repeatable="true" @repeatableRemoved="seasonRemoved(criterion)"  @repeatableAdded="seasonAdded(criterion)"
                  :minimum="valueOrDefault(criterion.parameter_options.seasons.min, criterion, 'Seasons')" :limit="valueOrDefault(criterion.parameter_options.seasons.max, criterion, 'Seasons')"
                  add-label="+ Add Season" :disabled="fieldDisabledForCriterion('Seasons', criterion)"
                  #default="{ index }">
                  <div class="d-flex">
                    <FormulateInput name="Name" type="select" label="Select Season" :options="getUnitList(index)" class="error-below"
                      :disabled="fieldDisabledForCriterion('Seasons', criterion)"
                      v-if="!showUnitName(index)"
                      @change="cascadeCriterionFieldChanges(criterionKey(criterion), 'Seasons', $event, index, 'season')"/>
                    <div v-else>
                      <FormulateForm class="d-flex align-items-center" >
                        <div class="d-flex flex-column position-absolute custom-options-control">
                          <b-icon icon="x-circle-fill" @click="closeCustomUnits(index)" class="mb-1" style="font-size: small;" variant="danger"></b-icon>
                          <b-icon icon="check-circle-fill" @click="addCustomUnit(index)" style="font-size: small;" variant="success"></b-icon>
                        </div>
                        <FormulateInput
                            v-on:keyup.enter="addCustomUnit(index)"
                            name="Name"
                            type="text"
                            v-model="customSeasonValue"
                            label="Custom Name"
                            id="custom-name"
                            class="mb-0"
                        />
                      </FormulateForm>
                    </div>
                    <FormulateInput  name="Start" type="select" label="Season Start" :options="monthList" class="ml-4 error-below"
                      :disabled="fieldDisabledForCriterion('Seasons', criterion)"
                      @change="cascadeCriterionFieldChanges(criterionKey(criterion), 'Seasons')"/>
                    <FormulateInput name="End" type="select" label="Season End" :options="monthList" class="ml-4 error-below"
                      :disabled="fieldDisabledForCriterion('Seasons', criterion)"
                      @change="cascadeCriterionFieldChanges(criterionKey(criterion), 'Seasons')"/>
                  </div>
                  <div v-if="checkForSeasonErrors(criterion, index)">
                    <ul class="formulate-input-errors">
                      <li class="formulate-input-error">
                        · Month ranges must be equal to or greater than 2 months
                      </li>
                    </ul>
                  </div>
                </FormulateInput>
              </div>
              <div v-if='criterionRequiresTimeOfDay(criterion)' class='no-top-margin-all-descendants'>
                <h4 class="input-title my-2">Time of Day</h4>
                <label class='input-label'>Copy initial parameters from</label>
                <FormulateInput
                  name='useSameTimeOfDayAs'
                  type='select'
                  class='use-same-as-dropdown'
                  @change="copyFieldFromOtherMetricToCriterion('TimeofDay', $event, criterion)"
                  :disabled="isDemoProject"
                  :options="useSameAsFieldForCriterionOptions('TimeofDay', criterion)"/>
                <FormulateInput class=" no-top-margin-all-descendants" type="group" name="TimeofDay" :repeatable="true"
                  :minimum="valueOrDefault(criterion.parameter_options.time_of_day.min, criterion, 'Time of Day')" :limit="valueOrDefault(criterion.parameter_options.time_of_day.max, criterion, 'Time of Day')"
                  add-label="+ Add Time of Day" :disabled="fieldDisabledForCriterion('TimeofDay', criterion)"
                  #default="{ index }">
                  <div>

                    <!-- we need this extra div in order for the default values to display properly -->
                    <div class="d-flex">
                      <FormulateInput name="Name" type="select" label="Select Time Range" :options="getUnitList(index, 'times')"  class="error-below"
                        :disabled="fieldDisabledForCriterion('TimeofDay', criterion)"
                        @change="cascadeCriterionFieldChanges(criterionKey(criterion), 'TimeofDay', $event, index, 'times')"
                         v-if="!showUnitName(index, 'times')"
                      />
                      <div v-else>
                      <FormulateForm class="d-flex align-items-center" >
                        <div class="d-flex flex-column position-absolute custom-options-control">
                          <b-icon icon="x-circle-fill" @click="closeCustomUnits(index,'times')" class="mb-1" style="font-size: small;" variant="danger"></b-icon>
                          <b-icon icon="check-circle-fill" @click="addCustomUnit(index,'times')" style="font-size: small;" variant="success"></b-icon>
                        </div>
                        <FormulateInput
                            v-on:keyup.enter="addCustomUnit(index, 'times')"
                            name="Name"
                            type="text"
                            v-model="customTimeValue"
                            label="Custom Name"
                            id="custom-name"
                            class="mb-0"
                        />
                      </FormulateForm>
                    </div>
                      <FormulateInput name="Start" type="select" label="Time Range Start" :options="timeList" class="ml-4 error-below"
                        :disabled="fieldDisabledForCriterion('TimeofDay', criterion)"
                        @change="cascadeCriterionFieldChanges(criterionKey(criterion), 'TimeofDay')"/>
                      <FormulateInput name="End" type="select" label="Time Range End" :options="timeList" class="ml-4 error-below"
                        :disabled="fieldDisabledForCriterion('TimeofDay', criterion)"
                        @change="cascadeCriterionFieldChanges(criterionKey(criterion), 'TimeofDay')"/>
                    </div>
                    <div v-if="checkforTimeErrors(criterion, index)">
                      <ul class="formulate-input-errors">
                        <li class="formulate-input-error">
                          · Time ranges must be equal to or greater than 3 hours
                        </li>
                      </ul>
                    </div>
                  </div>
                </FormulateInput>
              </div>
              <div v-if='criterionRequiresClothingProfile(criterion)'>
                <div class="d-flex">
                  <FormulateInput name="clothingProfile" type="select" label="Clothing Profile"
                    :options="clothingProfileList" :disabled="isDemoProject" />
                </div>
                <span class="learn-more">
                  <a href="https://help.orbitalstack.com/clothing-profiles/" target="_blank">
                    Learn more
                  </a> about these options.
                </span>
              </div>
              <div v-if='criterionRequiresActivity(criterion)'>
                <div class="d-flex">
                  <FormulateInput name="activity" type="select" label="Activity" :options="activityList"
                    :disabled="isDemoProject" />
                </div>
                <span class="learn-more">
                  <a href="https://help.orbitalstack.com/activity-types/" target="_blank">
                    Learn more
                  </a> about these options.
                </span>
              </div>
              <div v-if='criterionRequiresBuildingHeight(criterion) && !criterionRequiresInternalPressure(criterion)'>
                <FormulateInput
                  type="number"
                  name="buildingRoofHeight"
                  label="Building Roof Height (m)"
                  validation="optional|number|min:1"
                  class="mb-0 mt-2"
                  label-class="mr-2 form-font-size"
                  error-behavior="live"
                  :element-class="['mt-0','large-input']"
                  :wrapper-class="['d-flex','align-items-center', 'justify-content-between', 'pl-0']"
                  :help="getFormulateHelpSentence(criterion)"
                  min="1"
                >
                </FormulateInput>
              </div>
              <div v-if='criterionRequiresInternalPressure(criterion)'>
                <FormulateInput
                  v-if="!inputBuildingRoofAndEnclosureInsteadOfInternalPressure || !criterionRequiresBuildingRoofHeightOrInternalPressure(criterion)"
                  type="number"
                  name="internalPressure"
                  validation="optional|number|min:0"
                  label="Internal Pressure (kPa)"
                  class="mb-0 mt-2"
                  label-class="mr-2 form-font-size"
                  error-behavior="live"
                  :element-class="['mt-0','large-input']"
                  :wrapper-class="['d-flex','align-items-center', 'justify-content-between', 'pl-0']"
                  :help="getFormulateHelpSentence(criterion)"
                  @input="setBuildingHeightOrInternalPressureRequired(criterion)"
                  min="0"
                >
                  <template #label="{label, id}">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                      <label class="mr-2 mt-2" :for="id">{{ label }} </label>
                      <b-button size=small
                        v-if="criterionRequiresBuildingRoofHeightOrInternalPressure(criterion)"
                        type="button"
                        @click="inputBuildingRoofAndEnclosureInsteadOfInternalPressure = !inputBuildingRoofAndEnclosureInsteadOfInternalPressure"
                        variant=outline-secondary>
                        {{getFormulateButtonLabel}}
                      </b-button>
                    </div>
                  </template>
                </FormulateInput>

                <FormulateInput
                  v-else
                  type="number"
                  name="buildingRoofHeight"
                  label="Building Roof Height (m)"
                  validation="optional|number|min:1"
                  class="mb-0 mt-2"
                  label-class="mr-2 form-font-size"
                  error-behavior="live"
                  :element-class="['mt-0','large-input']"
                  :wrapper-class="['d-flex','align-items-center', 'justify-content-between', 'pl-0']"
                  :help="getFormulateHelpSentence(criterion)"
                  @input="setBuildingHeightOrInternalPressureRequired(criterion)"
                  min="1"
                >
                  <template #label="{label, id}">
                    <div class="d-flex flex-row align-items-center">
                      <label class="mr-2 mt-2" :for="id">{{ label }} </label>
                      <b-button size=small
                        v-if="criterionRequiresBuildingRoofHeightOrInternalPressure(criterion)"
                        type="button"
                        @click="inputBuildingRoofAndEnclosureInsteadOfInternalPressure = !inputBuildingRoofAndEnclosureInsteadOfInternalPressure"
                        variant="outline-secondary">
                        {{getFormulateButtonLabel}}
                      </b-button>
                    </div>
                  </template>
                </FormulateInput>
              </div>
              <div v-if='criterionRequiresEnclosureClassification(criterion) && inputBuildingRoofAndEnclosureInsteadOfInternalPressure'>
                <FormulateInput
                  name="buildingEnclosureClassification"
                    :options="[
                    { value: 0, label: 'Fully Open' },
                    { value: 0.55, label: 'Partially Open' },
                    { value:0.18, label: 'Fully Enclosed' },
                  ]"
                  type="select"
                  validation="required"
                  placeholder="Select an option"
                  label="Building Enclosure Classification"
                  class="mt-0 mb-1"
                  :element-class="['my-0','large-input']"
                  :wrapper-class="['d-flex','align-items-center', 'justify-content-between', 'pl-1']"
                  label-class="mr-2 form-font-size"
                /> <!--  -->
              </div>
              <div v-if='criterionRequiresNorthAngle(criterion)'>
                <FormulateInput
                  name="projectNorthAngle"
                  type="number"
                  validation="optional|number|min:0|max:359"
                  label="Project North Angle (°)"
                  class="mt-0 mb-1"
                  :element-class="['my-0','large-input']"
                  :wrapper-class="['d-flex','align-items-center', 'justify-content-between', 'pl-1']"
                  label-class="mr-2 form-font-size"
                  min="0"
                  max="359"
                /> <!--  -->
              </div>
              <div v-if='criterionRequiresReturnPeriod(criterion)'>
                <FormulateInput
                  type="group"
                  name="returnPeriods"
                  :repeatable="true"
                  add-label="+ Return Period"
                  :element-class="['px-3', 'outer-repeatable-group']"
                  class="border rounded mt-3"
                  :minimum="valueOrDefault(criterion.parameter_options['return periods'].min, criterion, null)"
                  :limit="valueOrDefault(criterion.parameter_options['return periods'].max, criterion, null)"
                  #default="{ index }"
                >
                  <div class="d-flex mt-0 flex-column">
                    <FormulateInput
                      type="number"
                      name="returnPeriod"
                      label="Return Period (years)"
                      validation="required|number|min:1"
                      :element-class="['return-period-item']"
                      class="mb-0"
                      min="1"
                    /> <!--  -->
                    <FormulateInput v-if='criterionRequiresDesignWindSpeed(criterion)'
                      type="number"
                      name="designWindSpeed"
                      label="Design Wind Speed (m/s)"
                      element-class="return-period-item"
                      validation="required|number|min:0"
                      class="mb-0"
                      min="0"
                    /> 
                    <div class="d-flex flex-row justify-content-between align-items-start" v-if='criterionRequiresDurstFactor(criterion)'>
                      <label class="formulate-input-label">Design Wind Speed Average Period</label>
                      <div class="d-flex flex-row align-items-start">
                        <FormulateInput 
                          type="select"
                          name="durstFactorDropdownItem"
                          element-class="return-period-item return-period-durst"
                          class="mr-5 mb-0"
                          validation="required"
                          :options="[
                            { value: 'Hourly Mean', label: 'Hourly Mean (Canadian Code)' },
                            { value: 'Ten Minute Average', label: '10 Min. Average (EU Code)' },
                            { value: 'Three Second Gust', label: '3 Sec. Gust (US Code)' },
                            { value: 'Custom', label: 'Custom Durst Factor' }]"
                          @input="selectedDurstFactorItemChanged(criterion, index)"
                        /> 
                        <label class="formulate-input-label durst-factor-label">Durst Factor</label>
                        <FormulateInput 
                          type="number"
                          name="durstFactor"
                          class="mt-0"
                          validation="required|number|min:0|max:1.8"
                          element-class="return-period-item"
                          :disabled="isDurstFactorInputDisabled(criterion, index)"
                        />
                        </div>
                    </div>
                    <FormulateInput v-if='criterionRequiresReferenceHeight(criterion)'
                      type="number"
                      name="referenceHeight"
                      label="Design Wind Speed Measurement Height (m)"
                      validation="required|number|min:0"
                      class="mb-0"
                      element-class="return-period-item"
                      min="0"
                    />
                  </div>
                </FormulateInput>
              </div>
              <div v-if='criterionRequiresSingleDate(criterion)' class='no-top-margin-all-descendants'>
                <h4 class="input-title my-2 ml-2">Dates</h4>
                <FormulateInput class=" no-top-margin-all-descendants" type="group" name="SingleDates" :repeatable="true"
                  :minimum="valueOrDefault(criterion.parameter_options.single_dates.min, criterion, 'SingleDate')" :limit="valueOrDefault(criterion.parameter_options.single_dates.max, criterion, 'SingleDate')"
                  add-label="+ Add Day" :disabled="fieldDisabledForCriterion('SingleDates', criterion)">
                  <FormulateInput
                    type="date"
                    name="Date"
                    validation="required"
                    error-behavior="live"
                    class='ml-2 single-input-width'
                  />
                </FormulateInput>
              </div>
              <div v-if='criterionRequiresSingleDayHours(criterion)' class='no-top-margin-all-descendants'>
                <h6 class="input-title my-2 ml-2 single-input-group">Hours</h6>
                <FormulateInput type="group" name="SingleDayHours">
                  <div>
                    <div class="d-flex">
                      <FormulateInput name="Start" type="time" label="Time Range Start" class="ml-2 error-below single-input-title"/>
                      <FormulateInput name="End" type="time" label="Time Range End" class="ml-2 error-below"/>
                    </div>
                    <div v-if="checkforSingleTimeErrors(criterion)">
                      <ul class="formulate-input-errors">
                        <li class="formulate-input-error">
                          · Time range must be at least three hours and within the same day 
                        </li>
                      </ul>
                    </div>
                  </div>
                </FormulateInput>
              </div>
              <div v-if='criterionRequiresMinutesInterval(criterion)' class='no-top-margin-all-descendants'>
                <h4 class="input-title my-2 ml-2 single-input-group">Analysis Intervals (min)</h4>
                <FormulateInput
                  type="number"
                  name="MinutesInterval"
                  placeholder="0"
                  help="This interval must be in minutes"
                  validation="required|number|min:5"
                  validation-name="Interval"
                  min="5"
                  error-behavior="live"
                  class='ml-2 single-input-width'
                />
              </div>
             </FormulateInput>
            <div class="submit-container">
              <div class="submit-button-container">
                <div class="loading-spinner-container">
                  <loading-state class="loading-spinner" v-if="isLoading" />
                </div>
              </div>
            </div>
          </b-tab>
          <div v-if='windDirectionsRequired'>
            <b-tab title="Wind Directions">
              <template v-if='companyIsRWDI || userIsSuperuser'>
                <div class="d-flex align-items-center justify-content-between mt-2 mb-4">
                  <p class="m-0 pr-3 flex-grow-1" style="border-right: 1px solid #ccc; max-width: 80%;">
                    Please select the wind directions you would like to simulate. For each
                    direction, please specify the type of terrain found in the immediate
                    area. <a href="https://help.orbitalstack.com/wind-directions-and-terrain-considerations-ai/" target="_blank">Learn more.</a>
                    <br>
                    <br>
                    You can also upload a CSV file containing all the values for Wind Direction and Roughness. 
                  </p>
                  <div class="ml-3 d-flex align-items-center justify-content-center" style="width: 30%;" id="wind-info-icon">
                  <FormulateInput
                    type="file"
                    name="csvUpload"
                    accept=".csv"
                    help="Upload CSV file with wind direction data"
                    :uploader="customCSVUploader"
                  />
                  </div>
                </div>
                <b-tooltip target="wind-info-icon" triggers="hover" custom-class="wind-direction-tooltip-class">
                  <pre> CSV file standard:
Wind Direction, Roughness
0, 3
10, 0.5
20, 0.3
...
350, 0.001</pre>
                </b-tooltip>
              </template>
              <template v-else>
                <p class="mt-2 mb-4">
                  Please select the wind directions you would like to simulate. For each
                  direction, please specify the type of terrain found in the immediate
                  area. <a href="https://help.orbitalstack.com/wind-directions-and-terrain-considerations-ai/" target="_blank">Learn more</a>
                </p>
              </template>
              <FormulateInput v-if='companyIsRWDI || userIsSuperuser' class="no-top-margin-all-descendants" type="group" name="windDirections"  :disabled='isDemoProject'
                :repeatable="true" add-label="+ Add Wind Direction" :minimum='minWindDirections' :limit='maxWindDirections' #default="{ index }">
                <div class="d-flex">
                  <FormulateInput name="windDirection" type="number" min='0' max='359' label="Wind Direction"
                    validation="number|min:0|max:359" error-behavior="live"
                    :disabled='isDemoProject' />
                  <FormulateInput v-if="isCladding" name="roughness" type="select" label="Wind Tunnel Profile" class="ml-4"
                   :disabled='isDemoProject' :options='windTunnelProfileList' />
                  <FormulateInput v-else name="roughness" type="number" label="Roughness" class="ml-4" min="0" max="3"
                    :validation="roughnessValidationString" error-behavior="live" :disabled='isDemoProject' />
                </div>
                <div v-if="windDirectionIsUnique(index)">
                  <ul class="formulate-input-errors">
                    <li class="formulate-input-error">
                      {{ duplicateWindDirectionErrorMessage(index) }}
                    </li>
                  </ul>
                </div>
              </FormulateInput>
              <FormulateInput v-else class="no-top-margin-all-descendants" type="group" name="windDirections"  :disabled='isDemoProject'
                :repeatable="true" add-label="+ Add Wind Direction" :minimum='minWindDirections' :limit='maxWindDirections' #default="{ index }">
                <div class="d-flex">
                  <FormulateInput name="windDirection" type="select" label="Wind Direction"
                    :disabled='isDemoProject' :options='windDirectionList' />
                  <FormulateInput v-if="isCladding" name="roughness" type="select" label="Wind Tunnel Profile" class="ml-4"
                   :disabled='isDemoProject' :options='windTunnelProfileList' />
                  <FormulateInput v-else name="roughness" type="select" label="Upwind Terrain" class="ml-4"
                    :disabled='isDemoProject' :options='PLWProfileList' />
                </div>
                <div v-if="windDirectionIsUnique(index)">
                  <ul class="formulate-input-errors">
                    <li class="formulate-input-error">
                      {{ duplicateWindDirectionErrorMessage(index) }}
                    </li>
                  </ul>
                </div>
              </FormulateInput>
              <FormulateInput type='textarea' name="scenario_note" @input="getNewScenarioNote" label="Scenario Note"
                placeholder='Note of the scenario (optional)' validation="optional"
                :disabled='isDemoProject' />
            </b-tab>
          </div>
        </b-tabs>
      </FormulateForm>
    </div>
    <div id="closed-simulation-panel" class="closed-simulation-panel" @click="toggleParameterPanel()" v-show="!active">
      <b-icon icon="chevron-up"></b-icon>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { BIcon, } from 'bootstrap-vue';
import {
  threeWindDirections,
  sixWindDirections,
  eightWindDirections,
  sixteenWindDirections,
  thirtySixWindDirections,
  oneSeason,
  twoSeasons,
  threeSeasons,
  fourSeasons,
  fiveSeasons,
  oneTimeOfDay,
  twoTimesOfDay,
  threeTimesOfDay,
  fourTimesOfDay,
  fiveTimesOfDay
} from '@/constants/defaultSimulationParams';
import { EventBus } from '@/network/eventbus';
import _ from 'lodash';
import {PLWROUGHNESSLIST, WINDTUNNELPROFILELIST, DEFAULT_CLADDING_H_REF, DEFAULT_CLADDING_ROUGHNESS, DEFAULT_PLW_H_REF, DEFAULT_PLW_ROUGHNESS} from '@/constants/terrain';
import * as d3 from 'd3';

export default {
  name: 'SelectSimulationParametersPanel',
  components: {
    BIcon
  },
  created() {
    EventBus.$on('MET_FETCH_ERROR', this.onFetchMetError);
  },
  beforeDestroy() {
    EventBus.$off('MET_FETCH_ERROR', this.onFetchMetError);
  },
  data() {
    return {
      customSeasonValue: '',
      customTimeValue: '',
      usingCustomSeasons: false,
      customSeasonIndex: null,
      customTimeIndex: null,
      customSeasons: [],
      customTimes: [],
      defaultsLoaded: {}, //object with properites for each config id that we'd loaded defaults for and a list of the criteria we've loaded default values for.  e.g. {"4127": ["abridged", "direction-wind"]}
      windDirectionsDefaultsLoaded: false,
      buildingHeightOrInternalPressureRequired: true,
      errorInitialized: false,
      currentCriteriaError: null,
      currentCriteriaErrorSource: null,
      windScaledError: false,
      windScaledSeasonMax: null,
      duplicateWindDirection: null,
      inputBuildingRoofAndEnclosureInsteadOfInternalPressure: false,
      active: true,
      windDirectionList: { 0: 'N (0°)', 22.5: 'NNE (22.5°)', 45: 'NE (45°)', 67.5: 'ENE (67.5°)', 90: 'E (90°)', 112.5: 'ESE (112.5°)', 135: 'SE (135°)',
        157.5: 'SSE (157.5°)', 180: 'S (180°)', 202.5: 'SSW (202.5°)', 225: 'SW (225°)', 247.5: 'WSW (247.5°)', 270: 'W (270°)', 292.5: 'WNW (292.5°)',
        315: 'NW (315°)', 337.5: 'NNW (337.5°)' },
      cfdParameterFormVersion: '2.0',
      simulationParameters: {
        formVersion: this.cfdParameterFormValues,
        windDirections: []
      },
      // timeofDayList and seasonList must be lowercase
      timeofDayList: ['Morning', 'Midday', 'Afternoon', 'Evening', 'Night', 'Daily'],
      seasonList: ['Annual', 'Spring', 'Summer', 'Autumn', 'Winter', 'Wet', 'Dry'],
      activityList: ['Leisurely Walking', 'Business Walking', 'Social/Light Sport', 'Spectating', 'Dining'],
      clothingProfileList: ['Northern Temperate Casual', 'Northern Temperate Business Casual', 'Northern Temperate Business',
        'Northern Moderate Casual', 'Northern Moderate Business Casual', 'Northern Moderate Business', 'Tropical Casual',
        'Tropical Business Casual', 'Tropical Business', 'Tropical Beach', 'Tropical Dining'],
      monthList: {1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September',
        10: 'October', 11: 'November', 12: 'December'},
      timeList: {0: '12:00 AM', 1: '1:00 AM', 2: '2:00 AM', 3: '3:00 AM', 4: '4:00 AM', 5: '5:00 AM', 6: '6:00 AM', 7: '7:00 AM',
        8: '8:00 AM', 9: '9:00 AM', 10: '10:00 AM', 11: '11:00 AM', 12: '12:00 PM', 13: '1:00 PM', 14: '2:00 PM', 15: '3:00 PM',
        16: '4:00 PM', 17: '5:00 PM', 18: '6:00 PM', 19: '7:00 PM', 20: '8:00 PM', 21: '9:00 PM', 22: '10:00 PM', 23: '11:00 PM'},
      currentConfigurationNote: null,
      formValidationDisabled: false,
      selectedMetSourceId: null,
      metrics: ['Abridged Wind Comfort Criteria' , 'Solar Exposure','Ventilation Potential', 'SPMV*', 'SPMV % Time Acceptable', 'SPMV % Time Comfortable','UTCI', 'UTCI Inbounds', 'Wind Intesity'],
      analysisType: ['Directional Wind' , 'Pedestrian Wind Comfort','Solar Analysis', 'Thermal Comfort'],
      layerType: ['Horizontal', 'Geometrical'],
      optionals: ['Seasons',  'Times Of Day', 'Wind Direction', 'Clothing Profile', 'Activitty Profile', 'Custom'],
    };
  },
  async mounted() {
    if(!_.isEmpty(this.submittedCFDParameters)){
      // If simulation has loaded before this component is mounted it will populate the form with the simulation parameters saved
      if(this.submittedCFDParameters.formVersion == this.cfdParameterFormVersion) //if the saved form version is the same as the current one the app is using then load the saved params
        this.simulationParameters = this.submittedCFDParameters;
    }else{
      // Simulation not loaded yet, when its loaded it gets initialized with methods below and possibly over-written a watch when the sim params load
      this.initializeWindDirectionFormParameters();
      this.initializeCriteriaFormParameters();
    }
    if (this.simulation.met_source_id) {
      this.selectedMetSourceId = this.simulation.met_source_id;
    } 
  },
  computed: {
    windTunnelProfileList() {
      return WINDTUNNELPROFILELIST;
    },
    PLWProfileList() {
      return PLWROUGHNESSLIST;
    },
    weatherErrorMsg() {
      if (this.isCladding) {
        return 'No valid meteorological has been added.  Please add a .a3n weather file to run UpX metrics';
      } else if (this.simulation.met_source_id == null) {
        return 'No meteorological data has been added to the project.  You must add meteorological data via the "Resources" tab in the project\'s "Properties" page before you can submit.';
      }else if (this.simulation.met_source_id != null && this.selectedProject.met_sources.find(x => x.id == this.simulation.met_source_id).error != null) {
        return 'The selected meteorological data has an error.  Please choose a new meteorlogical source via the "Resources" tab in the project\'s "Properties" page before you can submit.';
      } else {
        return '';
      }
    },
    simulationNeedsMet() {
      if (!this.simulation)
        return false;

      if (this.isCladding) {
        // if simulation requires ACK file (for UpX) return true
        let allCriteria = this.simulation.job_types_with_criteria[0].criteria;
        let requiresMetData = false;
        allCriteria.forEach(criteriaID => {
          let selectedCriteria = this.criteria.find(criterion => criterion.id === criteriaID);
          for (let key in selectedCriteria?.required_params) {
            if (key == 'ack_file' && selectedCriteria.required_params[key]=='required') {
              requiresMetData = true;
            }
          }
        });
        return requiresMetData;
      } else if (this.simulation.met_source_id == null) {
        //if the user doesn't have a met source selected at all (and it's not cladding) then they need to select one
        return true;
      }else if (this.simulation.met_source_id != null && this.selectedProject.met_sources.find(x => x.id == this.simulation.met_source_id).error != null) {
        //the user has a met source selected but it's in a error state
        return true;
      } else {
        //the user is RWDI or a superuser, let them select whatever they want
        return (this.userCompany?.is_rwdi || this.loggedInUser.is_superuser);
      }

    },
    getFormulateButtonLabel() {
      if (!this.inputBuildingRoofAndEnclosureInsteadOfInternalPressure) {
        return 'Specify Building Roof Height Instead';
      }
      return 'Specify Internal Pressure Instead';
    },
    allowedMetSources() {
      if (this.isCladding) {
        return ['A3N'];
      } else if (this.isThermalComfort) {
        return ['MEASURED', 'MODELLED', 'FUTURE', 'TC360'];
      } else {
        //PLW 
        return ['MEASURED', 'MODELLED', 'FUTURE', 'TC360', 'MIS'];
      }
    },
    metSourceOptions() {
      let options = [];
      this.selectedProject.met_sources.forEach(source => {
        if (source.error == null) {
          if(source.wind_data_type === 'MEASURED') {
            if (this.allowedMetSources.includes('MEASURED')) {
              options.push({
                value: source.id,
                text: source.station.name
              });
            }
          } else if (source.wind_data_type === 'MODELLED') {
            if(this.allowedMetSources.includes('MODELLED')) {
              options.push({
                value: source.id,
                text: 'Modelled at site'
              });
            }
          } else if (source.wind_data_type === 'CUSTOM') {
            if(this.allowedMetSources.includes(source?.custom_met_data?.file_format?.toUpperCase())) {
              if(source?.custom_met_data?.label){
                options.push({
                  value: source.id,
                  text: `${source.custom_met_data.label} (${source.custom_met_data.file_format})`
                });
              }else{
                options.push({
                  value: source.id,
                  text: `${decodeURI(String(source.custom_met_data.file).split('?')[0].split('/').pop())} (${source.custom_met_data.file_format})`
                });
              }
            }
          } else if (source.wind_data_type.startsWith('FUTURE')) {
            if(this.allowedMetSources.includes('FUTURE')) {
              let tokens = source.wind_data_type.split('-');
              options.push({
                value: source.id,
                text: `Future Met: scenario ${tokens[1]}, year ${tokens[2]}`
              });
            }
          }
        }
      });
      return options;
    },
    roughnessValidationString() {
      if (this.formValidationDisabled) {
        return 'number';
      } else {
        return 'number|min:0.005|max:3';
      }
    },
    directionScaled(){
      return this.simulationParameters['Directional Wind Scaled-PLW']?.[0];
    },
    abridgedSeasonCount(){
      return this.simulationParameters['RWDI Abridged-PLW']?.[0]?.Seasons.length;
    },
    selectedCriteriaWithParams() {
      if (this.criteria.length > 0) {

        let selectedCriteriaIds = this.simulation?.job_types_with_criteria.reduce((prev, current) => {
          return prev.concat(current.criteria);
        }, []);
        let selectedCriteria = selectedCriteriaIds?.map(criterionId => this.criteria.find(criterion => criterion.id === criterionId));
        return selectedCriteria.reduce((prev, current) => {
          if(current.required_params) {
            if(current.group) {
              let group_exists = !!prev.find(c => c.group === current.group);
              if(!group_exists) {
                return prev.concat([current]);
              } else {
                return prev;
              }
            } else {
              return prev.concat([current]);
            }
          } else {
            return prev;
          }
        }, []);
      } else {
        return [];
      }

    },
    minWindDirections() {
      return this.primaryJobType?.parameter_options?.wind_directions?.min;
    },
    maxWindDirections() {
      return this.primaryJobType?.parameter_options?.wind_directions?.max;
    },
    windDirectionsRequired() {
      return this.primaryJobType?.required_params?.wind_directions === 'required';
    },
    primaryJobType() {
      return this.jobTypes.find(x => x.id === this.simulation?.job_types_with_criteria?.[0]?.job_type);
    },
    isCladding() {
      if (this.jobTypes && this.jobTypes.length > 0) {
        let isCladding = false;
        for (let jtc of this.simulation.job_types_with_criteria) {
          let jt = this.jobTypes.find(x => x.id == jtc.job_type);
          if (jt.analysis_type.toUpperCase() == 'CLADDING') {
            isCladding = true;
            break;
          }
        }
        return isCladding;
      } else {
        return false;
      }
    },
    isThermalComfort() {
      if (this.jobTypes && this.jobTypes.length > 0) {
        let isTC = false;
        for (let jtc of this.simulation.job_types_with_criteria) {
          let jt = this.jobTypes.find(x => x.id == jtc.job_type);
          if (jt.analysis_type.toUpperCase() == 'TC') {
            isTC = true;
            break;
          }
        }
        return isTC;
      } else {
        return false;
      }
    },
    configurationId() {
      return this.$store.getters['project/createdScenarioId'] || this.$store.getters['project/simulationAsset/configurationId'];
    },
    submittedCFDParameters() {
      let params = this.$store.getters['project/simulationAsset/submittedCFDParameterFormValues'];
      return params;
    },
    companyIsRWDI() {
      return this.userCompany?.is_rwdi;
    },
    userIsSuperuser() {
      return this.loggedInUser?.is_superuser;
    },
    simulationIsML() {
      return this.simulation?.simulation_type === 'ML';
    },
    isDemoProject() {
      return this.selectedProject.is_demo_project;
    },
    ...mapGetters(['userCompany', 'loggedInUser']),
    ...mapGetters('project', ['createScenarioStep', 'createScenarioBtnClicked', 'backBtnClicked', 'selectedProject', 'jobTypes', 'criteria', 'scenarioSubmissionInProgress']),
    ...mapGetters('project/simulationAsset', ['simulation']),
  },
  methods: {
    customCSVUploader(file, progress, error) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
    
        reader.onload = (e) => {
          const csvData = e.target.result;
          if (!csvData.trim()) {
            error('The uploaded CSV file is empty.');
            reject(new Error('Empty CSV file'));
            return;
          }
          this.processCSVData(csvData).then(() => {
            progress(100);
            resolve({
              name: file.name,
              url: URL.createObjectURL(file),
              size: file.size,
            });
          }).catch(err => {
            error(err.message);
            reject(err);
          });
        };
    
        reader.onerror = (e) => {
          error(`Unable to read ${file.name}`);
          console.error(e);
          reject(e);
        };
    
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            const percentLoaded = Math.round((e.loaded / e.total) * 100);
            progress(percentLoaded);
          }
        };
    
        reader.readAsText(file);
      });
    },
    processCSVData(csvData) {
      return new Promise((resolve, reject) => {
        try {
          const rows = d3.csvParseRows(csvData);
          if (rows.length - 1 > this.maxWindDirections) {
            throw new Error(`CSV file contains more Wind Directions (${rows.length - 1}) than the scenario limit (${this.maxWindDirections}).`);
          }
          const headers = rows[0];
          const data = rows.slice(1).map(row => {
            const obj = {};
            headers.forEach((header, index) => {
              obj[header] = index === 0 ? row[index] : parseFloat(row[index]);
            });
            return obj;
          });

          this.populateWindDirections(data);
          resolve();
        } catch (error) {
          console.error('Error parsing CSV data:', error);
          reject(error);
        }
      });
    },
    populateWindDirections(data) {
      this.simulationParameters.windDirections = [];

      // Populate with new data
      data.forEach(item => {
        let roughness = this.isCladding ? this.getRoughnessLabel(item['Roughness']) : item['Roughness'];
        this.simulationParameters.windDirections.push({
          windDirection: item['Wind Direction'],
          roughness: roughness,
          h_ref: this.isCladding ? DEFAULT_CLADDING_H_REF : DEFAULT_PLW_H_REF
        });
      });

      // Ensure minimum number of wind directions
      while (this.simulationParameters.windDirections.length < this.minWindDirections) {
        console.warn('CSV file contains less Wind Directions than the scenario minimum:', this.minWindDirections);
        this.simulationParameters.windDirections.push({ 
          windDirection: 0, 
          roughness: this.isCladding ? DEFAULT_CLADDING_ROUGHNESS : DEFAULT_PLW_ROUGHNESS,
          h_ref: this.isCladding ? DEFAULT_CLADDING_H_REF : DEFAULT_PLW_H_REF
        });
      }
    },
    getRoughnessLabel(roughnessValue) {
    // Find the closest matching label in windTunnelProfileList
      return this.windTunnelProfileList.reduce((prev, curr) => 
        Math.abs(curr.value - roughnessValue) < Math.abs(prev.value - roughnessValue) ? curr : prev
      ).label;
    },
    routeTo() {
      return {
        name: 'ViewerContainer',
        params: {
          tabName: 'properties',
        },
        query: {
          tab: 'resources'
        }
      };
    },
    onFetchMetError(args) {
      const projectId = parseInt(args.project_id);
      let force_update_needed = false;
      if (this.selectedProject.id.toString() === projectId.toString()) {
        for (let metSource of this.selectedProject.met_sources) {
          if (metSource.id == args.met_source_id) {
            metSource.error = args.error;
            force_update_needed = true;
          }
        }
      }

      if (force_update_needed) {
        this.$forceUpdate();
      }
    },

    getFormulateHelpSentence(criterion) {
      if (this.criterionRequiresBuildingHeight(criterion)) {
        return 'Please enter either an Internal Pressure or the Building Roof Height';
      }
      return '';
    },
    //custom validation for file type as the default Mime/type validation on Vue Formulate does not work in Firefox
    validateFileType(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          let file_extension = file.name.match(new RegExp('[^.]+$'))[0];
          if ( file_extension.toLowerCase() !== 'a3n') {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }
        }
      }
      return true;
    },
    closeCustomUnits(index, unit='season'){
      if(unit == 'season'){
        if(index === this.customSeasonIndex){
          this.customSeasonIndex = null;
          this.customSeasonValue = '';
        }
      }else{
        if(index === this.customTimeIndex){
          this.customTimeIndex = null;
          this.customTimeValue = '';
        }
      }
    },
    getUnitList(index, unit='season') {
      let customUnits = unit == 'season' ? this.customSeasons : this.customTimes;
      let unitList = unit == 'season' ? this.seasonList : this.timeofDayList;
      for (let i = 0; i < customUnits.length; i++) {
        let element = String(customUnits[i]);
        let firstElement = element.charAt(0);
        if(Number(firstElement) == index){
          let unitsToAppend = String(customUnits[i]);
          unitsToAppend = unitsToAppend.split('_');
          unitsToAppend.shift();
          unitsToAppend.reverse();
          const mergeResult = [].concat(unitsToAppend, unitList);
          unitList = mergeResult;
        }
      }
      if(this.usingCustomSeasons && !unitList.includes('+ Add')){
        unitList.push('+ Add');
      }
      return unitList;
    },
    showUnitName(index, unit='season'){
      if(unit =='season' && index === this.customSeasonIndex)
        return true;
      if(unit !='season' && index === this.customTimeIndex)
        return true;
      return false;
    },
    resetCustomUnitState(unit){
      if(unit=='season'){
        this.customSeasonIndex = null;
        this.customSeasonValue = '';
      }else{
        this.customTimeIndex = null;
        this.customTimeValue = '';
      }
    },
    customUnitAlertEvents(unit, type){
      let variant, content = null;
      let customUnit = unit.charAt(0).toUpperCase() + unit.slice(1);
      switch (type) {
      case 'invalid':
        variant = 'danger';
        content = 'Only letters, numbers and dashes are allowed';
        break;
      case 'duplicate':
        variant = 'danger';
        content = `${customUnit} already exists`;
        break;
      default:
        variant = 'success';
        content = `${customUnit} was created`;
        break;
      }
      EventBus.$emit('TOAST', {
        variant: variant,
        content: content
      });
    },
    customUnitHasErrors(unit){
      // No underscores are allowed (Might break the duplication validation if underscores are present)
      let newUnit = unit == 'season' ? this.customSeasonValue.trim() : this.customTimeValue.trim();
      let lettersOnly = /^[A-Za-z0-9-]+$/.test(newUnit);
      if(!lettersOnly){
        this.customUnitAlertEvents(newUnit, 'invalid');
        return true;
      }

      //check to see if custom unit(Time or Season) exists in the default units' list
      newUnit = newUnit.toLocaleLowerCase();
      let defaultUnitList = unit == 'season' ? this.seasonList : this.timeofDayList;
      if(defaultUnitList.includes(newUnit)){
        this.customUnitAlertEvents(newUnit, 'duplicate');
        return true;
      }
      return false;
    },
    addCustomUnit(index, unit='season'){
      if(this.customUnitHasErrors(unit))
        return;

      // updates existing custom seasons
      let customUnits = unit == 'season' ? this.customSeasons : this.customTimes;
      let newUnit = unit == 'season' ? this.customSeasonValue.trim() : this.customTimeValue.trim();
      if(customUnits[0]){
        let customUnitExists = false;
        for (let i = 0; i < customUnits.length; i++) {
          let element = String(customUnits[i]);
          let firstElement = element.charAt(0);
          if(Number(firstElement) == index){
            let existingCustomUnits = element.split('_');
            const nameAlreadyExists = existingCustomUnits.includes(newUnit);
            if(!nameAlreadyExists){
              let newUnitName = `${element}_${newUnit}`;
              if(unit=='season'){
                this.customSeasons[i] = newUnitName;
              }else{
                this.customTimes[i] = newUnitName;
              }
              this.resetCustomUnitState(unit);
              this.customUnitAlertEvents(newUnit, 'new');
            }else{
              this.customUnitAlertEvents(newUnit, 'duplicate');
            }
            customUnitExists = true;
          }
        }
        // If was not season found then initialize custom seasons
        if(customUnitExists==false){
          let newSeasonName = `${index}_${newUnit}`;
          customUnits.push(newSeasonName);
          this.resetCustomUnitState(unit);
          if(unit == 'season'){
            this.customSeasons = customUnits;
          }else{
            this.customTimes = customUnits;
          }

          this.customUnitAlertEvents(newUnit, 'new');
        }
      }else{
        // If there are no custom seasons then initialize custom seasons
        let newUnitName = `${index}_${newUnit}`;
        let newUnitArray = unit == 'season' ? this.customSeasons : this.customTimes;
        newUnitArray = [newUnitName];
        if(unit == 'season'){
          this.customSeasons = newUnitArray;
        }else{
          this.customTimes = newUnitArray;
        }
        this.resetCustomUnitState(unit);
        this.customUnitAlertEvents(newUnit, 'new');
      }
    },
    seasonAdded(criterion){
      if(this.criterionKey(criterion) === 'RWDI Abridged-PLW' && this.directionScaled?.useSameSeasonsAs === this.criterionKey(criterion)){
        if(this.abridgedSeasonCount > this.windScaledSeasonMax){
          this.currentCriteriaError = 'Directional Wind Scaled';
          this.currentCriteriaErrorSource = this.criterionLabel(criterion);
          this.windScaledError = true;
        }else{
          this.errorInitialized = false;
          this.windScaledError = false;
        }
      }
    },
    seasonRemoved(criterion){
      if(this.criterionKey(criterion) === 'RWDI Abridged-PLW' && this.directionScaled?.useSameSeasonsAs === this.criterionKey(criterion)){
        if(this.abridgedSeasonCount > this.windScaledSeasonMax){
          this.errorInitialized = true;
          this.windScaledError = true;
        }else{
          this.currentCriteriaErrorSource = null;
          this.currentCriteriaError = null;
          this.errorInitialized = false;
          this.windScaledError = false;
        }
      }
    },
    showCriteriaError(criterion){
      if(this.criterionKey(criterion) === 'Directional Wind Scaled-PLW' && this.windScaledError){
        this.currentCriteriaError = this.criterionLabel(criterion);
        return true;
      }
      return false;
    },
    async toggleFormValidation() {
      this.formValidationDisabled = !this.formValidationDisabled;

      let temp = _.clone(this.simulationParameters);
      this.simulationParameters = {};
      await this.$nextTick();
      this.simulationParameters = _.clone(temp);

    },
    valueOrDefault(value, criterion, inputField) {
      let key = this.criterionKey(criterion);
      if (key in this.simulationParameters) {
        if (inputField == 'Seasons') {

          if (this.simulationParameters[key][0].useSameSeasonsAs == 'custom') { //only allow the number of seasons to be changed if "custom" is selected
            return value;
          } else {
            return criterion.parameter_options.seasons.default;
          }

        } else if (inputField == 'Time of Day') {

          if (this.simulationParameters[key][0].useSameTimeOfDayAs == 'custom') { //only allow the number of seasons to be changed if "custom" is selected
            return value;
          } else {
            return criterion.parameter_options.time_of_day.default;
          }

        } else {
          return value;
        }
      } else
        return value;

    },
    fieldDisabledForCriterion(field, criterion) {
      if(this.isDemoProject) return true;
      let key = this.criterionKey(criterion);
      if(this.simulationParameters[key]) {
        let criterionParams = this.simulationParameters[key][0];
        if(field === 'Seasons') {
          return criterionParams.useSameSeasonsAs !== 'custom';
        } else if (field === 'TimeofDay') {
          return criterionParams.useSameTimeOfDayAs !== 'custom';
        }
      } else {
        return false;
      }
    },
    cascadeCriterionFieldChanges(criterionKey, field, custom=null, index=null, unit='season') {
      if(custom?.target?.value){
        if(custom.target.value == '+ Add'){
          if(unit=='times'){
            this.customTimeIndex = index;
          }else{
            this.customSeasonIndex = index;
          }
        }
      }
      Object.keys(this.simulationParameters).forEach(parameterKey => {
        if(['windDirections', 'formVersion'].includes(parameterKey)) return;
        if(field === 'Seasons') {
          if(this.simulationParameters[parameterKey][0]['useSameSeasonsAs'] === criterionKey) {
            this.simulationParameters[parameterKey][0][field] = _.cloneDeep(this.simulationParameters[criterionKey][0][field]);
            this.cascadeCriterionFieldChanges(parameterKey, field);
          }
        } else if (field === 'TimeofDay') {
          if(this.simulationParameters[parameterKey][0]['useSameTimeOfDayAs'] === criterionKey) {
            this.simulationParameters[parameterKey][0][field] = _.cloneDeep(this.simulationParameters[criterionKey][0][field]);
            this.cascadeCriterionFieldChanges(parameterKey, field);
          }
        }
      });
    },
    copyFieldFromOtherMetricToCriterion(field, e, criterion) {
      let key = this.criterionKey(criterion);
      let useSameAs = e.target.value;
      if(this.simulationParameters[key][0][field]) {
        if(useSameAs === 'custom'){
          this.usingCustomSeasons = true;
        }else{
          this.usingCustomSeasons = false;
        }
        if(useSameAs === 'default') {
          if(field === 'Seasons') {
            this.setCriterionSeasonsToDefaults(criterion);
          } else if (field === 'TimeofDay') {
            this.setCriterionTimeOfDayToDefaults(criterion);
          }
          this.cascadeCriterionFieldChanges(key, field);
        } else if(useSameAs !== 'custom') {
          this.simulationParameters[key][0][field] = _.cloneDeep(this.simulationParameters[useSameAs][0][field]);
          this.cascadeCriterionFieldChanges(key, field);
        } else if (useSameAs === 'custom') {
          //need to refresh the min/limit values on the seasons input group so the "add seasons button will appear"
          this.cascadeCriterionFieldChanges(key, field);
        }
      }
    },
    useSameAsFieldForCriterionOptions(field, criterion) {

      let criteria_property_name = '';
      switch (field) {
      case 'Seasons':
        criteria_property_name = 'seasons';
        break;
      case 'TimeofDay':
        criteria_property_name = 'time_of_day';
        break;
      default:
        break;
      }

      let options = [{label: 'Default', value: 'default'}, {label: 'Custom', value: 'custom'}];
      let key = this.criterionKey(criterion);
      if(this.simulationParameters[key]) {
        //let fieldLength = this.simulationParameters[key][0][field].length;
        let maxLengthForField = criterion.parameter_options[criteria_property_name].max;
        this.selectedCriteriaWithParams.forEach(metric => {
          let currentKey = this.criterionKey(metric);
          if(currentKey === key || currentKey === 'formVersion' || currentKey === 'windDirections' || !(currentKey in this.simulationParameters) ) return;
          if(this.simulationParameters[currentKey][0][field]?.length <= maxLengthForField) {
            options.push({
              label: this.criterionLabel(metric),
              value: currentKey
            });
          }
        });
        return options;
      } else {
        return options;
      }
    },
    initializeWindDirectionFormParameters() {
      if(this.windDirectionsRequired && !this.windDirectionsDefaultsLoaded) {
        switch(this.primaryJobType?.parameter_options?.wind_directions.default) {
        case 3:
          this.simulationParameters.windDirections = threeWindDirections;
          break;
        case 6:
          this.simulationParameters.windDirections = sixWindDirections;
          break;
        case 8:
          this.simulationParameters.windDirections = eightWindDirections;
          break;
        case 16:
          this.simulationParameters.windDirections = sixteenWindDirections;
          break;
        case 36:
          this.simulationParameters.windDirections = thirtySixWindDirections;
          break;
        default:
          this.simulationParameters.windDirections = eightWindDirections;
        }
        this.windDirectionsDefaultsLoaded = true;
      }

      //for cladding jobs we let the user pick from the wind tunnel profile list instead of the roughness list and the WT profiles don't contain a 0.5 roughness equivalent
      //which is the default roughness value for PLW.  Instead we need to update all of those to 0.24 which is the "suburban" option.
      if (this.isCladding) {
        for (let wd of this.simulationParameters.windDirections) {
          wd.roughness = DEFAULT_CLADDING_ROUGHNESS;
          // for cladding we want to set an h_ref of 609.  For PLW we can leave it blank and it'll default to 60
          wd.h_ref = DEFAULT_CLADDING_H_REF;
        }
      }
    },
    initializeCriteriaFormParameters() {
      if(this.selectedCriteriaWithParams){
        for (let criterion of this.selectedCriteriaWithParams) {
          let criterionKey = this.criterionKey(criterion);

          if (!(this.configurationId in this.defaultsLoaded)) {
            this.defaultsLoaded[this.configurationId] = [];
          }

          this.simulationParameters[criterionKey] = [{}];
          this.setCriterionParametersToDefaults(criterion);
          if (!this.defaultsLoaded[this.configurationId].includes(criterionKey)) {
            this.defaultsLoaded[this.configurationId].push(criterionKey);
          }
        }
      }
    },
    setCriterionParametersToDefaults(criterion) {
      let key = this.criterionKey(criterion);

      // populate useSameAs fields (not necessary for Wind Pressure Estimates)
      if(!this.criterionRequiresReturnPeriod(criterion)){
        this.simulationParameters[key][0].useSameSeasonsAs = 'default';
        this.simulationParameters[key][0].useSameTimeOfDayAs = 'default';
      }

      if(this.criterionRequiresReturnPeriod(criterion)){
        this.setCriterionReturnPeriodsToDefaults(criterion);
        this.storeInvalidReturnPeriods({'criteria': criterion.name, 'is_invalid': true});  //by default there's an error on the form and the user needs to enter an Internal Pressure or Building Roof Height to correct it
      }

      // populate seasons defaults
      if(this.criterionRequiresSeasons(criterion)) {
        this.setCriterionSeasonsToDefaults(criterion);
      }

      // populate time of day defaults
      if(this.criterionRequiresTimeOfDay(criterion)) {
        this.setCriterionTimeOfDayToDefaults(criterion);
      }

      // populate clothing profile default
      if(this.criterionRequiresClothingProfile(criterion)) {
        this.simulationParameters[key][0].clothingProfile = 'Northern Temperate Casual';
      }

      // populate activity default
      if(this.criterionRequiresActivity(criterion)) {
        this.simulationParameters[key][0].activity = 'Leisurely Walking';
      }

      // populate building enclosure classification
      if (this.criterionRequiresEnclosureClassification(criterion)) {
        this.simulationParameters[key][0].buildingEnclosureClassification = 0.55;
      }

      if (this.criterionRequiresSingleDate(criterion)) {
        this.simulationParameters[key][0].SingleDates = [{Date: '2024-03-21'}];
      }

      if (this.criterionRequiresSingleDayHours(criterion)) {
        this.simulationParameters[key][0].SingleDayHours = [{Start: '09:00', End: '17:00'}];
      }

      if (this.criterionRequiresMinutesInterval(criterion)) {
        this.simulationParameters[key][0].MinutesInterval = 15;
      }

    },
    setCriterionReturnPeriodsToDefaults(criterion) {
      let key = this.criterionKey(criterion);
      this.simulationParameters[key][0].returnPeriods = [
        {
          returnPeriod: 50
        }
      ];

      // populate design wind speed
      if (this.criterionRequiresDesignWindSpeed(criterion)) {
        this.simulationParameters[key][0].returnPeriods[0].designWindSpeed = 40;  
      }

      // populate reference height
      if (this.criterionRequiresReferenceHeight(criterion)) {
        this.simulationParameters[key][0].returnPeriods[0].referenceHeight = 10;   
      }

      // populate durst factor | Canadian Code
      if (this.criterionRequiresDurstFactor(criterion)) {
        this.simulationParameters[key][0].returnPeriods[0].durstFactor = 1;
        this.simulationParameters[key][0].returnPeriods[0].durstFactorDropdownItem = 'Hourly Mean';
      }

    },
    setCriterionSeasonsToDefaults(criterion) {
      let key = this.criterionKey(criterion);

      switch(criterion.parameter_options.seasons.default) {
      case 1:
        this.simulationParameters[key][0].Seasons = [...oneSeason];
        break;
      case 2:
        this.simulationParameters[key][0].Seasons = [...twoSeasons];
        break;
      case 3:
        this.simulationParameters[key][0].Seasons = [...threeSeasons];
        break;
      case 4:
        this.simulationParameters[key][0].Seasons = [...fourSeasons];
        break;
      case 5:
        this.simulationParameters[key][0].Seasons = [...fiveSeasons];
        break;
      }
    },
    setCriterionTimeOfDayToDefaults(criterion) {
      let key = this.criterionKey(criterion);

      switch(criterion.parameter_options.time_of_day.default) {
      case 1:
        this.simulationParameters[key][0].TimeofDay = [...oneTimeOfDay];
        break;
      case 2:
        this.simulationParameters[key][0].TimeofDay = [...twoTimesOfDay];
        break;
      case 3:
        this.simulationParameters[key][0].TimeofDay = [...threeTimesOfDay];
        break;
      case 4:
        this.simulationParameters[key][0].TimeofDay = [...fourTimesOfDay];
        break;
      case 5:
        this.simulationParameters[key][0].TimeofDay = [...fiveTimesOfDay];
        break;
      }
    },
    criterionKey(criterion) {
      return `${this.criterionLabel(criterion)}-${criterion.analysis_type}`;
    },
    criterionLabel(criterion) {
      return criterion.group ? criterion.group : criterion.name;
    },
    criterionRequiresSeasons(criterion) {
      return criterion.required_params?.seasons === 'required';
    },
    criterionRequiresTimeOfDay(criterion) {
      return criterion.required_params?.time_of_day === 'required';
    },
    criterionRequiresClothingProfile(criterion) {
      return criterion.required_params?.clothing_profile === 'required';
    },
    criterionRequiresActivity(criterion) {
      return criterion.required_params?.activity === 'required';
    },
    // criterionRequiresACKFile(criterion) {
    //   return criterion.required_params?.ack_file === 'required';
    // },
    criterionRequiresBuildingHeight(criterion) {
      return criterion.required_params?.building_height === 'required';
    },
    criterionRequiresInternalPressure(criterion) {
      return criterion.required_params?.internal_pressure === 'required';
    },
    criterionRequiresBuildingRoofHeightOrInternalPressure(criterion) {
      //both of these are marked as "required" on the criteria but in fact we need either internal pressure OR the roof height 
      //combined with the building enclosure classification, which lets us calculate internal pressure during post processing
      //This calculation only occurs for worst case pressure estimates though.  For UpX we require the internal pressure override
      return this.criterionRequiresBuildingHeight(criterion) && this.criterionRequiresInternalPressure(criterion);
    },
    criterionRequiresEnclosureClassification(criterion) {
      return criterion.required_params?.enclosure_classification === 'required';
    },
    criterionRequiresNorthAngle(criterion) {
      return criterion.required_params?.north_angle === 'required';
    },
    criterionRequiresDesignWindSpeed(criterion) {
      return criterion.required_params?.design_wind_speed === 'required';
    },
    criterionRequiresReferenceHeight(criterion) {
      return criterion.required_params?.reference_height === 'required';
    },
    criterionRequiresDurstFactor(criterion) {
      return criterion.required_params?.durst_factor === 'required';
    },
    criterionRequiresReturnPeriod(criterion) {
      return criterion.required_params['return periods'] === 'required';
    },
    criterionRequiresSingleDate(criterion) {
      return criterion.required_params?.single_date === 'required';
    },
    criterionRequiresSingleDayHours(criterion) {
      return criterion.required_params?.single_day_hours === 'required';
    },
    criterionRequiresMinutesInterval(criterion) {
      return criterion.required_params?.minutes_interval === 'required';
    },
    formValidationStateChanged(validationState) {
      this.storeformErrors({ 'component': 'SimulationParameters', 'hasErrors': validationState.hasErrors });
      if (validationState.hasErrors && validationState.errors.length == 0) {
        //console.log(this.simulationParameters);
        //hack to make sure everything is re-bound properly.  fixes weird validation errors on the form?
        console.log('Form is invalid');
      }
    },
    toggleParameterPanel() {
      this.active = !this.active;
    },
    setBuildingHeightOrInternalPressureRequired(criterion) {
      let dataEntryRequired = false;
      const tab_name = `${criterion.group ? criterion.group : criterion.name}-${criterion.analysis_type}`;
      if (tab_name in this.simulationParameters  && !this.formValidationDisabled) {
        if(!this.simulationParameters[tab_name][0]?.buildingRoofHeight){
          //no building roof height
          if (!('internalPressure' in this.simulationParameters[tab_name][0])
            || this.simulationParameters[tab_name][0].internalPressure == '') {
            //no internal pressure
            dataEntryRequired = true;
          }
        }

      }

      this.storeInvalidReturnPeriods({'criteria': criterion.name, 'is_invalid': dataEntryRequired});
      this.buildingHeightOrInternalPressureRequired = dataEntryRequired;
    },
    windDirectionIsUnique(index) {
      if (!this.formValidationDisabled) {
        let allWindDirections = this.simulationParameters.windDirections;

        if(!allWindDirections || !allWindDirections[index]){
          return false;
        }

        let duplicated = {};
        if (Array.isArray(allWindDirections)) {
          let groups = _.groupBy(allWindDirections, 'windDirection');
          for (let key of Object.keys(groups)) {
            if (groups[key].length > 1) {
              duplicated[key] = groups[key].length;
            }
          }
        }

        if(Object.keys(duplicated).length > 0){
          this.storeInvalidWinds(true);
        }else{
          this.storeInvalidWinds(false);
        }

        if(allWindDirections[index].windDirection in duplicated){
          return true;
        }
      }
      return false;
    },
    duplicateWindDirectionErrorMessage(index) {
      let currentWindDirection = this.simulationParameters.windDirections[index]?.windDirection;
      if (currentWindDirection in this.windDirectionList)
        return `${this.windDirectionList[currentWindDirection]} wind direction exists more than once`;
      else
        return `Wind direction ${currentWindDirection} exists more than once`;
    },
    getNewScenarioNote(data) {
      this.currentConfigurationNote = data;
    },
    getMonthDifference(seasons) {
      return Math.abs(seasons.End - seasons.Start);
    },
    invalidInterval(seasonInterval, timeUnit) {
      if (seasonInterval == null) {
        return false;
      }
      const interval = [];
      var maxInterval = 0;

      if (timeUnit == 'M') {
        maxInterval = 1;
      } else if (timeUnit == 'H') {
        maxInterval = 3;
      }

      seasonInterval.forEach(item => interval.push(this.getMonthDifference(item)));
      return interval.some(num => num < maxInterval);
    },
    invalidCriterionSeasons(data, criterion) {

      if(this.criterionKey(criterion) === 'Directional Wind Scaled-PLW'){
        this.windScaledSeasonMax = criterion.parameter_options.seasons.max;
      }
      if(!data || !data[this.criterionKey(criterion)]) {

        return false;
      } else {
        // check all criterion for errors
        let invalidSeasonsProvided = Object.keys(data).some(criterion => {
          // windDirections and formVersion are not criterion
          if(criterion === 'windDirections' || criterion === 'formVersion') return false;
          return this.invalidSeasons(data[criterion][0]);
        });

        // flag validation error in store if *any* criterion invalid
        if (invalidSeasonsProvided && !this.formValidationDisabled) {
          this.storeInvalidSeasons(true);
        } else {
          this.storeInvalidSeasons(false);
        }

        // return invalid for this criterion
        //return this.invalidSeasons(data[this.criterionKey(criterion)][0]);
      }
    },
    invalidCriterionTimeofDay(data, criterion) {

      //if(!data || !data[this.criterionKey(criterion)] || !data[criterion]) {
      if(!data || !data[this.criterionKey(criterion)]) {
        return false;
      } else {
        // check all criterion for errors
        let invalidTimesProvided = Object.keys(data).some(criterion => {
          if(criterion === 'windDirections') return false;
          return this.invalidTimeofDay(data[criterion][0]);
        });

        // flag validation error in store if *any* criterion invalid
        if (invalidTimesProvided && !this.formValidationDisabled) {
          this.storeInvalidTimes(true);
        } else {
          this.storeInvalidTimes(false);
        }

        // return invalid for this criterion
        //return this.invalidTimeofDay(data[this.criterionKey(criterion)][0]);
      }
    },
    invalidSeasons(data) {
      if (data == null) {
        return false;
      }
      return this.invalidInterval(data?.Seasons, 'M');
    },
    invalidTimeofDay(data) {
      if (data == null) {
        return false;
      }
      return this.invalidInterval(data?.TimeofDay, 'H');
    },
    async updateScenario() {
      if(this.selectedProject.is_demo_project) return null;

      try {
        this.setScenarioSubmissionInProgress(true);
        //https://vueformulate.com/guide/inputs/types/select/#select-2
        /*    Although Vue Formulate supports non-string values, HTML select inputs only support string values. As such when using a Number
        *     as an option value the value will automatically be re-cast as a string.
        */
        //This will cast them back to numbers
        for (let wd of this.simulationParameters.windDirections) {
          // Note:  there was a weird validation error happening with the wind directions and roughnesses set to 'required' so I removed that validation method
          // and check for them here instead.  The validation error had something to do with this form being mounted, unmounted, then remounted, but I was unable
          // to fully figure out why that caused a problem.  It's perhaps possible to fix the mounted, unmounted, mounted issue but that happens because the ViewerContainer's
          // IsInbound calculated property is set, unset, then set again on scenario creation.
          if (wd.windDirection === '' || wd.roughness === '') {
            EventBus.$emit('TOAST', { variant: 'danger', content: 'One of more wind directions or roughness values are missing'});
            this.setScenarioSubmissionInProgress(false);
            return false;
          }
          wd.roughness = Number(wd.roughness);
          wd.windDirection = Number(wd.windDirection);
        }
        
        if ('csvUpload' in this.simulationParameters) {
          delete this.simulationParameters.csvUpload;
        }

        await this.storeUpdateScenario({
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          configurationId: Number(this.configurationId),
          scenario: {
            name: this.simulation.label,
            note: this.currentConfigurationNote,
            simulation_type: this.simulation.simulation_type,
            simulation_id: this.simulation.id,
            cfd_parameters: this.simulationParameters,
            met_source_id: this.selectedMetSourceId
          },
          cfdParameterValues: this.simulationParameters,
        });
        this.setScenarioSubmissionInProgress(false);
        return true;
      } catch (error) {
        this.setScenarioSubmissionInProgress(false);
        return false;
      }
    },
    ...mapActions({
      storeUpdateScenario: 'project/updateScenario', //Used to update scenario
      storeformErrors: 'project/setformErrors', //Used to update the simulation parameters flags
      storeInvalidSeasons: 'project/setInvalidSeasons', //Used to update the simulation parameters flags
      storeInvalidTimes: 'project/setInvalidTimes', //Used to update the simulation parameters flags
      storeInvalidWinds: 'project/setInvalidWinds', //Used to update the simulation parameters flags
      storeInvalidReturnPeriods: 'project/setInvalidReturnPeriods', //userd to update the simulation parameters flags
      storeInvalidSingleDate: 'project/setInvalidSingleDate', //Used to update the simulation parameters flags
      storeInvalidSingleDayHours: 'project/setInvalidSingleDayHours', //Used to update the simulation parameters flags
      storeInvalidMinutesInterval: 'project/setInvalidMinutesInterval', //Used to update the simulation parameters flags
      setScenarioSubmissionInProgress: 'project/setScenarioSubmissionInProgress',
      setSelectedMetId: 'project/simulationAsset/setSelectedMetId'
    }),
    checkForSeasonErrors(criterion, index) {

      // updates the 'canSubmit' status
      this.invalidCriterionSeasons(this.simulationParameters, criterion);

      // checks if the Seasons parameters are set
      const allSeasons = this.simulationParameters[this.criterionKey(criterion)][0]?.Seasons;
      if(!allSeasons){
        return false;
      }
      // checks if the Season for the specific row is set
      const currentSeason = allSeasons[index];
      if(!currentSeason){
        return false;
      }

      // checks for Season error on the specific row
      if(Math.abs(currentSeason.End-currentSeason.Start) < 1){
        return true;
      }else{
        return false;
      }
    },
    checkforTimeErrors(criterion, index) {

      // updates the 'canSubmit' status
      this.invalidCriterionTimeofDay(this.simulationParameters, criterion);

      // checks if the TimeOfDay parameters are set
      const allTimes = this.simulationParameters[this.criterionKey(criterion)][0]?.TimeofDay;
      if(!allTimes){
        return false;
      }
      // checks if the TimeOfDay for the specific row is set
      const currentTime = allTimes[index];
      if(!currentTime){
        return false;
      }

      // checks for TimeOfDay error on the specific row
      if(Math.abs(currentTime.End-currentTime.Start) < 3){
        return true;
      }else{
        return false;
      }
    },
    checkforSingleTimeErrors(criterion) {

      // updates the 'canSubmit' status
      //this.invalidCriterionTimeofDay(this.simulationParameters, criterion);

      // checks if the SingleDayHours parameters are set
      let key = this.criterionKey(criterion);
      if (key in this.simulationParameters) {
        const allTimes = this.simulationParameters[key][0]?.SingleDayHours;
        if(!allTimes){
          return false;
        }
        // checks if the SingleDayHours for the specific row is set
        const currentTime = allTimes[0];
        if(!currentTime){
          return false;
        }

        // checks for SingleDayHours error on the specific row
        
        if (!currentTime.End || !currentTime.Start) {
          this.storeInvalidSingleDayHours(true);
          return true;
        }
        let startHour = parseInt(currentTime.Start.split(':')[0]);
        let startMinute = parseInt(currentTime.Start.split(':')[1]);
        let endHour = parseInt(currentTime.End.split(':')[0]);
        let endMinute = parseInt(currentTime.End.split(':')[1]);
        let timeDifference = (endHour*60+endMinute) - (startHour*60+startMinute);
        if(timeDifference < 180){
          this.storeInvalidSingleDayHours(true);
          return true;
        }else{
          this.storeInvalidSingleDayHours(false);
          return false;
        }
      }
    },
    isDurstFactorInputDisabled(criterion, group_index) {
      if (group_index !== undefined) {
        let key = this.criterionKey(criterion);
        if (key in this.simulationParameters) {
          let selectedItem = this.simulationParameters[this.criterionKey(criterion)][0].returnPeriods[group_index].durstFactorDropdownItem;
          if (selectedItem === 'Custom') {
            return false;
          }
        }
      }
     
      return true;
    },
    selectedDurstFactorItemChanged(criterion, group_index) {
      let selectedItem = this.simulationParameters[this.criterionKey(criterion)][0].returnPeriods[group_index].durstFactorDropdownItem;
      if (selectedItem === 'Hourly Mean') {
        this.simulationParameters[this.criterionKey(criterion)][0].returnPeriods[group_index].durstFactor =  1;
      } else if (selectedItem === 'Ten Minute Average') {
        this.simulationParameters[this.criterionKey(criterion)][0].returnPeriods[group_index].durstFactor = 1.067;
      } else if (selectedItem === 'Three Second Gust') {
        this.simulationParameters[this.criterionKey(criterion)][0].returnPeriods[group_index].durstFactor = 1.525;
      }
    },
  },
  watch: {
    selectedMetSourceId(newValue) {
      if (newValue) {
        //set the selected met source on the simulation, which will signal to simulation-steps-panel that MET has been selected
        this.setSelectedMetId(newValue);
      }
    },
    metSourceOptions(newValue) {
      if (newValue) {
        if (newValue.length > 0 && this.selectedMetSourceId == null) {
          this.selectedMetSourceId = newValue[0].value;
        }
      }
    },
    windScaledError: {
      handler(newValue) {
        if(newValue == true && this.errorInitialized == false){
          EventBus.$emit('TOAST', {
            variant: 'danger',
            content: `${this.currentCriteriaError} metric is no longer able to copy seasons from ${this.currentCriteriaErrorSource}`
          });
          this.windScaledError = newValue;
          this.errorInitialized = true;
        }
      }
    },
    async createScenarioBtnClicked(newValue) {
      if (newValue) {
        let scenarioSaved = await this.updateScenario();
        if (scenarioSaved) {
          this.$store.dispatch('project/setScenarioConfirmationModalVisibility', true);
        } else {
          this.$store.dispatch('project/setCreateScenarioBtnClicked', false);
        }
      }
    },
    async backBtnClicked(newValue) {
      if (newValue) {
        await this.updateScenario();
      }
    },
    submittedCFDParameters(newValue) {
      if(newValue.formVersion == this.cfdParameterFormVersion) //if the saved form version is the same as the current one the app is using then load the saved params
        this.simulationParameters = newValue;
    },
    configurationId() {
      if(!this.submittedCFDParameters?.formVersion) {
        this.windDirectionsDefaultsLoaded = false; // variable reset needed to initialize the parameters
        //switched to a scenario that doesn't have any parameters saved, initialize the form with defaults.
        //Note:  when switching to a config that does have saved params, the submittedCFDParameters watch above will set the parameters up
        this.simulationParameters = {
          formVersion: this.cfdParameterFormValues,
          windDirections: []
        };
        this.initializeWindDirectionFormParameters();
        this.initializeCriteriaFormParameters();
      }
      this.selectedMetSourceId = this.simulation.met_source_id;
      this.$forceUpdate();
    },
    selectedCriteriaWithParams(newValue) {
      //this was previously called during mounted() but sometimes selectedCriteriaWithParams wasn't set yet
      if (newValue != []) {
        //if the parameters are already loaded because they were previously saved, then don't override them
        //this watch gets triggered when the full simulation list finishes loading so it's important to make sure we don't reset the form parameters
        //tot he defaults if they are set to a saved value before the full collection of simulations loads
        if (_.isEmpty(this.simulationParameters)) {  
          this.initializeCriteriaFormParameters();
        }
      }
    },
    primaryJobType(newValue) {
      if (newValue && this.simulationParameters.windDirections.length === 0) {
        this.initializeWindDirectionFormParameters();  //this was previously in mounted() but this.jobTypes was sometimes not loaded yet
      }
    }
  }
};
</script>

<style>
.wind-direction-tooltip-class .tooltip-inner pre {
  color: white; /* Set text color to white */
}
.custom-upload-area ul.formulate-files > li {
  display: none !important;
}

#gltf-uploads.formulate-input >  ul.formulate-files{
  display: none !important;
}

.formulate-input[data-classification=select] select[name="custom-name"] option[value="+ Add"]{
  font-weight: 600;
}

.custom-options-control{
  z-index: 99999999;
  left: 8.150rem;
  margin-top: 1.313rem;
}

#custom-name{
  width: 7.063rem;
}

.select-container select{
  text-transform: lowercase;
}

.select-container::before{
  opacity: 0;
}

.repeatable-return-period div.formulate-input ul.formulate-input-errors{
  float: left;
}

.building-roof-height > .formulate-input-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 49px 0 20px;
}

.form-font-size{
  font-size: 14.4px;
}

.internal-pressure-container div.formulate-input-group-repeatable{
  padding: 0 !important;
}

#years{
  background-color: #cecece;
  text-align: left;
}

.return-period-container .formulate-input-group-repeatable{
  padding: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.large-input{
  width: 132px !important;
}

.return-period-item{
  float: right !important;
  margin-bottom: 1px !important;
  margin-top: -30px;
}

.durst-factor-label {
  margin-top: -24px;
  margin-right: 5px
}

.return-period-item select, .return-period-item input{
  width: 86px !important;
}


.return-period-durst select, .return-period-durst input{
  width: 233px !important;
}

.outer-repeatable-group > div.formulate-input-group-add-more{
  margin-top: 0;
}

#building-roof-input  ul.formulate-input-errors{
  float: right;
}

.outer-repeatable-group .formulate-input-grouping{
  overflow-y: unset;
}

div.project-north + ul.formulate-input-errors{
  float: right;
  margin-right: 94px;
}

.repeatable-padding .formulate-input-group-repeatable{
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.return-period{
  margin-right: 4.063rem;
}

.formulate-input-grouping {
  overflow-y: auto;
}

.toggle-form-validation-checkbox .custom-control-input {
  position: absolute;
  width: 3.5%;
  left: 60.8%;
  top: 10%;
}

</style>
<style scoped>
.custom-file-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  min-height: 32px;
  line-height: 1.2;
  font-weight: normal;
  margin-top: -24.89px;
}

.custom-file-upload:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.custom-file-upload label {
  font-weight: normal;
  margin: 0;
  padding: 0;
}
.pressure-error {
  margin-top: -1rem;
  margin-bottom: 2rem;
}

.header-container {
  white-space: nowrap;
}

.header-text {
  display: inline-block;
  margin-right: 0.625rem;
}

hr {
  margin-left: -1.25rem;
  width: calc(100% + 2.5rem);
}

.col-4 {
  flex: 0 0 30.2%;
  max-width: 30.2%;
}

.form-group {
  position: absolute;
}

.custom-control-inline {
  padding-left: 0;
}

.activity-group-checkbox::before {
  content: "";
  border: 0.125rem;
  border-style: solid;
}

.close-simulation-panel-btn:hover {
  background-color: #dbdbdb;
  color: #303030;
  cursor: pointer;
}

.close-simulation-panel-btn {
  background-color: var(--grey-200);
  position: absolute;
  width: 1.875rem;
  height: 1.875rem;
  top: 4%;
  left: 93%;
  border-radius: 3.125rem;
  text-align: center;
}

.chevron-icon {
  margin-top: 0.469rem;
}

.bi-chevron-up {
  padding-left: 50%;
}

.closed-simulation-panel:hover {
  background-color: #dbdbdb;
  color: #303030;
  cursor: pointer;
}

.closed-simulation-panel {
  background-color: var(--grey-100);
  width: 0.938rem;
  min-width: 0.938rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  position: absolute;
  height: 0.938rem;
  width: 76%;
  bottom: 3.2em;
  background-color: var(--grey-100);
  left: 11%;
  text-align: center;
  z-index: 2;
}

.error-message{
  padding: .5em;
  color: #ad0606;
  font-size: .8em;
  font-weight: 500;
  line-height: 1.5
}

.select-simulation-container {
  padding: 1.25em;
  margin: 0;
  position: absolute;
  height: 65%;
  width: 76%;
  top: calc(25% - 0.313rem);
  background-color: var(--grey-100);
  left: 11%;
  border-top-right-radius: 1.563rem;
  border-top-left-radius: 1.563rem;
  overflow-y: scroll;
  z-index: 2;
}

.learn-more{
  margin-bottom: 2.5em;
  font-size: 0.7rem;
  font-weight: 400;
}

.input-title{
  font-size: 0.85rem;
  font-weight: 550;
}

.input-label {
  font-size: 0.7rem;
  font-weight: 400;
}

.use-same-as-dropdown {
  width: 23.75rem;
}

.error-below {
  margin-bottom: 0;
}

.single-input-width {
  width: 11rem;
}

.single-input-group {
  margin-top: 1.5rem !important;
}

.single-input-title{
  font-size: 1rem;
  font-weight: 550;
}
</style>
